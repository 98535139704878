import { Country } from '@backmarket/http-api'

export const VALIDATE_STREET_NUMBER = {
  [Country.JP]: false,
  default: true,
}

export const VALIDATE_STREET_AUTOCOMPLETED = {
  [Country.JP]: false,
  default: true,
}

export const VALIDATE_POSTAL_CODE_AUTOCOMPLETED = {
  [Country.JP]: true,
  default: false,
}
