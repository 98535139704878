import { useRuntimeConfig } from '#imports'
import { ref } from 'vue'

/**
 * _Frustration Signals_, and more specifically _Rage Clicks_,
 * describe users who interact multiple times with the UI in a short period of time.
 *
 * When it happens, it's a good indicator that the user is frustrated with the UI
 * (e.g.: lack of feedback, slow loading times, impossible submission/navigation).
 *
 * See: https://docs.datadoghq.com/real_user_monitoring/platform/dashboards/usage/#frustration-signals
 *
 * This composable allows you to detect these events, and act accordingly.
 *
 * Sample usage:
 * ```js
 * const logger = useLogger()
 * const { recordEvent } = useRageEventsDetection()
 *
 * function onClick(event: Event) {
 *   const isRageEvent = recordEvent(event)
 *
 *   if (isRageEvent) {
 *     logger.info('Rage click detected')
 *   }
 * }
 * ```
 */
export function useRageEventsDetection() {
  const { RAGE_EVENTS_DETECTION_THRESHOLD, RAGE_EVENTS_DETECTION_TIME_SPAN } =
    useRuntimeConfig().public

  const eventsDates = ref<number[]>([])

  function recordEvent(event: Event) {
    eventsDates.value.push(event.timeStamp)

    if (eventsDates.value.length > RAGE_EVENTS_DETECTION_THRESHOLD) {
      eventsDates.value.shift()
    }

    if (eventsDates.value.length === RAGE_EVENTS_DETECTION_THRESHOLD) {
      const lastEventTime = eventsDates.value[eventsDates.value.length - 1]
      const firstEventTime = eventsDates.value[0]
      const ellapsedTime = lastEventTime - firstEventTime

      if (ellapsedTime < RAGE_EVENTS_DETECTION_TIME_SPAN) {
        return true
      }
    }

    return false
  }

  return { recordEvent }
}
