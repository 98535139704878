export default {
  submit: {
    id: 'checkout_collection_point_form_submit',
    defaultMessage: 'Next',
  },
  phoneHelperMessage: {
    id: 'checkout_collection_point_form_phone_helper_message',
    defaultMessage: 'We need it for the delivery.',
  },
  userInformationTitle: {
    id: 'checkout_collection_point_form_user_information_title',
    defaultMessage: 'Personal information',
  },
}
