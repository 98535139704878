export default {
  submit: {
    id: 'checkout_shipping_address_form_submit',
    defaultMessage: 'Next',
  },
  submitABTest: {
    id: 'checkout_shipping_address_form_submit_abtest',
    defaultMessage: 'Go to confirmation',
  },
  submitABTestBilling: {
    id: 'checkout_shipping_address_form_go_billing_abtest',
    defaultMessage: 'Go to billing',
  },
  confirmationWarningTitle: {
    id: 'checkout_shipping_address_form_warning_title',
    defaultMessage: 'Are you sure about your address?',
  },
  confirmationWarningContent: {
    id: 'checkout_shipping_address_form_warning_content',
    defaultMessage:
      'We couldn’t verify your address. Make sure it’s correct before you save it.',
  },
  streetNumberWarningTitle: {
    id: 'checkout_shipping_address_form_street_number_warning_title',
    defaultMessage: 'Are you sure about your address?',
  },
  streetNumberWarningContent: {
    id: 'checkout_shipping_address_form_street_number_warning_content',
    defaultMessage:
      'Street number is missing. Make sure it’s correct before you save it.',
  },
  phoneHelperMessage: {
    id: 'checkout_shipping_address_form_phone_text_regular',
    defaultMessage:
      'We need it for the delivery, just in case we need to reach you.',
  },
  sameBillingAddressLabel: {
    id: 'checkout_shipping_address_form_same_address_label',
    defaultMessage: 'Same billing address',
  },
  userInformationTitle: {
    id: 'checkout_shipping_address_form_user_information_title',
    defaultMessage: 'Personal information',
  },
  phoneHelperMessageAlternative: {
    id: 'checkout_shipping_address_form_phone_text_regular_copy',
    defaultMessage: 'Just in case we need to reach you about delivery.',
  },
  shippingAddressTitle: {
    id: 'checkout_shipping_information_title',
    defaultMessage: 'Shipping Address',
  },
}
