<template>
  <div>
    <UnavailableCountry class="my-24" />

    <CollectionPointForm
      v-if="addressStore.hasCollectionPoint"
      :api-errors
      :birthdate="formValues.birthdate"
      :first-name="formValues.firstName"
      :last-name="formValues.lastName"
      :phone="formValues.phone"
      :submitting="isFormDisabled"
      :with-user-information="userInformationStore.isFormRequired"
      @submit="handleCollectionPointSubmit"
      @submit-failed="handleShippingAddressSubmitFailure"
    />

    <ShippingAddressForm
      v-if="!addressStore.hasCollectionPoint"
      :address="formValues"
      :api-errors
      :countries
      :country="countryCode"
      :submitting="isFormDisabled"
      :with-user-information="userInformationStore.isFormRequired"
      @submit="handleShippingAddressSubmit"
      @submit-failed="handleShippingAddressSubmitFailure"
    />

    <ItemsNotReserved class="mt-12 md:hidden" />
    <GDPRNotice page="delivery" />

    <ReassuranceItems class="mt-24 md:mt-56">
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>
  </div>
</template>

<script setup>
import { useRouter } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { postShippings } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import {
  getPhoneNumberInfos,
  getPhoneNumberToE164,
} from '@ds/components/InputPhone'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import GDPRNotice from '../../components/GDPRNotice/GDPRNotice.vue'
import ItemsNotReserved from '../../components/ItemsNotReserved/ItemsNotReserved.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser.ts'
import { CHECKOUT } from '../../routes-names'
import { useAddressStore } from '../../stores/addressStore'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useSwapStore } from '../../stores/swapStore'
import { useUserInformationStore } from '../../stores/userInformationStore'
import { getAPIErrors } from '../../utils/getAPIErrors'

import CollectionPointForm from './components/CollectionPointForm/CollectionPointForm.vue'
import ShippingAddressForm from './components/ShippingAddressForm/ShippingAddressForm.vue'
import UnavailableCountry from './components/UnavailableCountry/UnavailableCountry.vue'

import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems'

const isRemovingItem = ref(false)
const apiErrors = ref({})
const selectedShippings = ref({})

const router = useRouter()
const logger = useLogger()
const tracking = useTracking()

const {
  market: { countryCode },
} = useMarketplace()
const userStore = useUserStore()

const addressStore = useAddressStore()
const cartStore = useCartStore()
const userInformationStore = useUserInformationStore()
const loaderStore = useLoaderStore()
const swapStore = useSwapStore()

const { openErrorToast } = useTheToast()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

onMounted(async () => {
  try {
    tracking.trackFunnel(cartStore.trackingData(CHECKOUT.SHIPPING_ADDRESS))
  } catch {
    // Empty catch until we tackle the following issue: CK-3553
  } finally {
    loaderStore.disable()
  }
})

const address = computed(() => {
  const shippingCountry = addressStore.shipping.country

  if (
    shippingCountry &&
    !addressStore.shippableCountries.some(
      (country) => country.countryCode === addressStore.shipping.country,
    )
  ) {
    return { country: countryCode }
  }

  return addressStore.hasCollectionPoint
    ? addressStore.collectionPoint
    : addressStore.shipping
})

const formValues = computed(() => {
  const phone = `${address.value.phone || userStore.user.phone || ''}`
  const countryDialInCode = `${address.value.countryDialInCode || userStore.user.countryDialInCode || ''}`

  logger.debug('[Checkout] phone number parsing: ', {
    owners: ['bot-squad-checkout-front'],
    phone:
      phone && countryDialInCode
        ? getPhoneNumberToE164(countryDialInCode, phone)
        : phone,
  })

  return {
    ...address.value,
    country: address.value.country || countryCode || '',
    firstName: address.value.firstName || userStore.user.firstName || '',
    lastName: address.value.lastName || userStore.user.lastName || '',
    phone:
      phone && countryDialInCode
        ? getPhoneNumberToE164(countryDialInCode, phone)
        : phone,

    ...insertIf(userInformationStore.isFormRequired, {
      birthdate: userInformationStore.birthdate || '',
      nationalId: userInformationStore.nationalId,
    }),
  }
})

const countries = computed(() => {
  return [...addressStore.shippableCountries]
    .map(({ name, countryCode: shippableCountryCode }) => ({
      value: shippableCountryCode,
      label: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
})

const isFormDisabled = computed(() => {
  return loaderStore.isEnabled || isRemovingItem.value
})

const allShippingsSelected = computed(() => {
  return Object.values(selectedShippings.value).every(Boolean)
})

const handleCollectionPointSubmit = async ({
  firstName,
  lastName,
  phone,
  birthdate,
}) => {
  loaderStore.enable()

  tracking.trackClick({
    name: '3_shipping-new-edit_cta_continue',
    zone: 'funnel',
  })

  try {
    const { dial, nationalNumber } = getPhoneNumberInfos(phone, countryCode)

    if (isEmpty(nationalNumber)) {
      logger.debug('[CHECKOUT] Failed to convert phone in shipping form', {
        phone,
      })
    }

    await addressStore.saveCollectionPoint({
      address: {
        ...address.value,
        firstName,
        lastName,
        phone: nationalNumber,
        countryDialInCode: dial,
        ...insertIf(userInformationStore.isFormRequired, {
          birthdate,
        }),
      },
      ...insertIf(userInformationStore.isFormRequired, {
        formType: userInformationStore.formType,
      }),
    })

    // Reload checkout store
    await cartStore.fetchCart()

    router.push({
      name: CHECKOUT.BILLING_ADDRESS,
    })
  } catch (error) {
    if (error?.status === 400) {
      const errors = getAPIErrors(error)

      apiErrors.value = errors
      openErrorToast()
    } else
      await handleUnauthorizedUser(
        error,
        '[CHECKOUT] Failed to submit collection point form',
      )

    loaderStore.disable()
  }
}

const handleShippingAddressSubmitFailure = (errors) => {
  logger.error('[CHECKOUT] Failed to submit shipping form', {
    errors: { ...errors },
    owners: ['bot-squad-checkout-front'],
  })
  tracking.trackClick({
    name: '3_shipping-new-edit_cta_continue_failed_attempt',
    zone: 'funnel',
  })
}

const handleShippingAddressSubmit = async (values, sameAsShipping) => {
  apiErrors.value = {}

  if (!allShippingsSelected.value) {
    return
  }

  loaderStore.enable()

  try {
    // If needed, save shippings
    if (!isEmpty(selectedShippings.value)) {
      await $httpFetch(postShippings, {
        body: Object.entries(selectedShippings.value).map(
          ([itemId, shipping]) => ({
            listingId: Number(itemId),
            shippingId: shipping.shippingId,
            shipperDisplay: shipping.shipperDisplay,
          }),
        ),
      })
    }

    const { dial, nationalNumber } = getPhoneNumberInfos(
      values.phone,
      values.country,
    )

    const shippingAddress = {
      ...values,
      phone: nationalNumber,
      countryDialInCode: dial,
    }

    if (isEmpty(nationalNumber)) {
      logger.debug('[CHECKOUT] Failed to convert phone in shipping form', {
        phone: values.phone,
      })
    }

    if (swapStore.hasOffer) {
      await addressStore.createBuybackAddress({
        ...shippingAddress,
      })
    }

    if (cartStore.hasBouyguesMobilePlan) {
      await addressStore.saveBouyguesAddress({
        isShipping: true,
        isBilling: sameAsShipping,
        address: shippingAddress,
      })
    }

    await addressStore.saveAddress({
      isShipping: true,
      isBilling: sameAsShipping,
      ...insertIf(userInformationStore.isFormRequired, {
        formType: userInformationStore.formType,
      }),
      address: shippingAddress,
    })

    tracking.trackClick({
      name: '3_shipping-new-edit_cta_continue',
      zone: 'funnel',
    })

    // Reload checkout store
    await cartStore.fetchCart()

    router.push({
      name: sameAsShipping
        ? CHECKOUT.ADDRESS_CONFIRMATION
        : CHECKOUT.BILLING_ADDRESS,
    })
  } catch (error) {
    tracking.trackClick({
      name: '3_shipping-new-edit_cta_continue_failed_attempt',
      zone: 'funnel',
    })

    if (error?.status === 400) {
      const errors = getAPIErrors(error)

      apiErrors.value = errors
      openErrorToast()
    } else
      await handleUnauthorizedUser(
        error,
        '[CHECKOUT] Failed to submit shipping form',
      )

    loaderStore.disable()
  }
}
</script>
