<template>
  <RevForm
    :initial-values
    method="POST"
    :validate="validate()"
    @on-submit-failed="handleSubmitFailed"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <RevCard class="p-24">
        <DynamicAddressFieldValidatorsErrorLogger
          :errors
          :form-values="values"
          logger-scope="CHECKOUT_COLLECTION_POINT"
        />
        <AddressFields
          v-model:first-name="values.firstName"
          v-model:last-name="values.lastName"
          v-model:phone="values.phone"
          :default-country="MARKET_COUNTRY_CODE"
          :errors="allErrors(errors)"
          :feature-code="FeatureCode.WEB_CHECKOUT_COLLECTION_POINTS"
          :phone-help-message="i18n(translations.phoneHelperMessage)"
        />

        <div
          v-if="!withUserInformation"
          class="mt-7 flex flex-col items-stretch md:items-end"
        >
          <RevButton
            data-qa="submit-button"
            :disabled="submitting"
            full-width="adaptive"
            type="submit"
            variant="primary"
          >
            {{ i18n(translations.submit) }}
          </RevButton>
        </div>
      </RevCard>

      <div v-if="withUserInformation" class="mt-8">
        <h2 class="heading-3">
          {{ i18n(translations.userInformationTitle) }}
        </h2>
        <RevCard class="mt-4 p-24 lg:mt-6">
          <UserInformationFields v-model:birthdate="values.birthdate" :errors />

          <div class="mt-7 flex flex-col items-stretch md:items-end">
            <RevButton
              data-qa="submit-button"
              :disabled="submitting"
              full-width="adaptive"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.submit) }}
            </RevButton>
          </div>
        </RevCard>
      </div>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { reactive } from 'vue'

import type { Country } from '@backmarket/http-api'
import type { AddressFieldsValues } from '@backmarket/http-api/src/api-specs-shipping/address/address-validation.types'
import AddressFields from '@backmarket/nuxt-module-address/AddressFields.vue'
import { AddressFieldsValidators } from '@backmarket/nuxt-module-address/AddressFieldsValidators'
import DynamicAddressFieldValidatorsErrorLogger from '@backmarket/nuxt-module-address/DynamicAddressFieldValidatorsErrorLogger.vue'
import { InputAddressBirthdateValidators } from '@backmarket/nuxt-module-address/InputAddressBirthdateValidators'
import UserInformationFields from '@backmarket/nuxt-module-address/UserInformationFields.vue'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import {
  type ErrorsOf,
  RevForm,
  type ValidatorDeclaration,
  makeValidate,
} from '@ds/components/Form'

import translations from './CollectionPointForm.translations'

const i18n = useI18n()

// @TODO: Need to do this cast because MarketCountryCode and Country are not compatible
const MARKET_COUNTRY_CODE = useMarketplace().market
  .countryCode as unknown as Country

const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()

const props = withDefaults(
  defineProps<{
    submitting?: boolean
    birthdate?: string
    firstName: string
    lastName: string
    phone: string
    apiErrors: { [key: string]: string }
    withUserInformation?: boolean
  }>(),
  {
    submitting: false,
    birthdate: '',
    withUserInformation: false,
  },
)

type FormValues = Required<
  Pick<AddressFieldsValues, 'firstName' | 'lastName' | 'phone'>
> &
  Pick<AddressFieldsValues, 'birthdate'>

const initialValues = reactive<FormValues>({
  firstName: props.firstName,
  lastName: props.lastName,
  phone: props.phone,
  ...insertIf(props.withUserInformation, {
    birthdate: props.birthdate,
  }),
})

const emit = defineEmits(['submit', 'submit-failed'])

const fallbackValidators: ValidatorDeclaration<AddressFieldsValues> = {
  ...AddressFieldsValidators(i18n),
  birthdate: InputAddressBirthdateValidators(i18n),
}

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_CHECKOUT_COLLECTION_POINT',
  initialFormValues: initialValues,
  fallbackValidators,
  metadata: {
    scope: 'customer',
  },
})

function validate() {
  return makeValidate<FormValues>(addressValidation)
}

const allErrors = (errors: Partial<typeof initialValues>) => {
  return { ...errors, ...props.apiErrors }
}

const handleSubmitFailed = ({ errors }: { errors: ErrorsOf<FormValues> }) => {
  emit('submit-failed', {
    ...errors,
  })
}
// Adding [birthdate] prop as required since it's validated by the InsertIf
// but TS can't safe check the condition
const handleSubmit = (values: FormValues) => {
  const { firstName, lastName, phone, birthdate } = values

  emit('submit', {
    firstName,
    lastName,
    phone,
    ...insertIf(props.withUserInformation, {
      birthdate,
    }),
  })
}
</script>
