export default {
  title: {
    id: 'checkout_cross_border_country_error_banner_title',
    defaultMessage: 'At least one item can’t ship.',
  },
  content: {
    id: 'checkout_cross_border_country_error_banner_description',
    defaultMessage:
      'Your address is outside the country you’re browsing in. Edit your address or country before moving on.',
  },
} as const
