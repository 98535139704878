<template>
  <RevInfoBlock
    v-if="shouldDisplay"
    :content="i18n(translations.content)"
    :icon="IconWarning"
    :title="i18n(translations.title)"
    variant="danger"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import { useAddressStore } from '../../../../stores/addressStore'

import translations from './UnavailableCountry.translations'

const i18n = useI18n()
const { shipping, shippableCountries } = useAddressStore()

const shouldDisplay = computed(() => {
  // When the customer has no pre-defined address (i.e.: new user)
  if (!shipping.country) {
    return false
  }

  return !shippableCountries.some(
    ({ countryCode }) => countryCode === shipping.country,
  )
})
</script>
